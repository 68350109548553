import transactionsService from "../../../../services/transactionsService";
import currenciesService from "../../../../services/currenciesService";
import $formatNumber from "../../../../mixins/formatNumber";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../../../store/types";

export default {
    name: "wallet-management",
    components: {},
    props: [],
    mixins: [$formatNumber],
    data() {
        return {
            address: "",
            addAddressValue: "",
            currentPage: 1,
            tableHeaders: ["نام ارز", "مقدار بالانس", "آدرس", "عملیات"],
            convertedIcon: "",
            convertedTitle: "",
            convertedISO: "",
        };
    },
    computed: {
        ...mapGetters({
            bitrahCurrencies: types.GET_BITRAH_CURRENCIES,
        }),
    },
    mounted() {
        this.getAdminAddressesFunc();
        this.getBitrahCurrenciesFunc();
    },
    methods: {
        ...mapActions({
            bitrahCurrenciesAction: types.ACTION_BITRAH_CURRENCIES,
        }),
        getBitrahCurrenciesFunc() {
            currenciesService
                .getBitrahCurrencies()
                .then((response) => {
                    this.bitrahCurrenciesAction(response.data);
                    this.convertedIcon = response.data[0].logo;
                    this.convertedTitle = response.data[0].name;
                    this.convertedISO = response.data[0].iso;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        changeCurrency(currency) {
            this.convertedIcon = currency.logo;
            this.convertedTitle = currency.name;
            this.convertedISO = currency.iso;
        },
        getAdminAddressesFunc() {
            transactionsService
                .getAdminAddresses(
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then((response) => {
                    this.address = response.data;
                });
        },
        onSubmit(evt) {
            evt.preventDefault();
            // alert(JSON.stringify(this.form));
            transactionsService
                .addAdminAddresses(
                    this.convertedISO,
                    this.addAddressValue,
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then((response) => {
                    this.address.push(response.data);
                    this.reset();
                    this.hideModal();
                })
                .catch((err) => {
                    this.$bvToast.toast(err.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        reset() {
            this.addAddressValue = "";
        },
        hideModal() {
            this.$refs["modal-1"].hide();
        },
        removeAddress(id) {
            this.$bvModal
                .msgBoxConfirm("آیا با حذف کردن این آدرس موافق هستید؟", {
                    modalClass: "rtl lighterFontColor boxShadow",
                    bodyClass: "rtl lighterBackgroundColor",
                    okTitle: "بله",
                    okVariant: "danger dangerBackgroundColor px-5",
                    cancelVariant:
                        "custom lighterFontColor lighterBackgroundColor",
                    cancelTitle: "خیر",
                    footerClass: "p-2 lighterBackgroundColor border-0",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((condition) => {
                    if (condition) {
                        transactionsService
                            .removeAdminAddresses(
                                id,
                                localStorage.getItem("bitrahAdminAccessToken")
                            )
                            .then(() => {
                                this.getAdminAddressesFunc();
                            });
                    }
                })
                .catch(() => {
                });
        },
        toggleStatusAddress(id, status) {
            status = !status;
            transactionsService
                .toggleStatusAdminAddresses(
                    id,
                    status,
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then(() => {
                    this.getAdminAddressesFunc();
                });
        },
    },
};
